AUI().ready(

    /*
    This function gets loaded when all the HTML, not including the portlets, is
    loaded.
    */

    function() {
        window.Parsley.addValidator('checkEmailDomain', {

            validateString: function (value, requirement) {
                var res = isNotAllowedDomain(value);
                return !res;
            },
            messages: {
                it: 'Email non supportata. Registrarsi con un dominio email internazionale, ad esempio .com o .net'
            }
        });

        function isNotAllowedDomain(email) {
            let split = $("#emailDomainsNotAllowed").val().split(",");
            let domain = email.substring(email.lastIndexOf("."), email.length);
            let notAllowed = split.includes(domain);
            return notAllowed;
        }
    }
);

